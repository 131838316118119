import React, { useEffect } from "react";
import HeaderContatoCSS from './HeaderContato.css';
import Navbar from '../Navbar/Navbar';
import { useTranslate } from "../../helpers/helpers";


function HeaderContato () {

    const t = useTranslate();


    return (
        <section className="header-empresa flex items-center justify-center md:justify-start" style={{height: '50vh'}}>
            <div className="flex flex-col items-center text-center md:items-start text-md-start mx-14">
                <h1 className="text-4xl md:text-4xl lg:text-6xl font-bold my-10 text-black" style={{ fontFamily: 'Anek Latin, sans' }}>
                    {t('form_title')}
                </h1>
            </div>
    </section>
    )

}


export default HeaderContato;