import React from "react";
import './Video.css';
import VideoAleatorio from '../../../assets/videos/Video.mp4'

function Video() {
    return(
        <section>
            <div className="video-container ms-5">
                <video className="video-bg" autoPlay loop muted>
                    <source src={VideoAleatorio} type="video/mp4" />
                    Seu navegador não suporta vídeos em HTML5.
                </video>
            </div>

            
        </section>
    )
}

export default Video;