import React from "react";
import { useEffect } from "react";
import HeaderEmpresa from '../Components/Header-Empresa/HeaderEmpresa';
import GrupoNortem from '../Components/Grupo-Nortem/GrupoNortem';
import Group from '../Components/Group/Group';
import Cidades from '../Components/Cidades-Empresa/Cidades'
import Footer2 from '../Components/Footer2/Footer'
import Navbar from "../Components/Navbar/Navbar";
import { usePageTitle, useTranslate } from "../helpers/helpers";

export default function Empresa(){
    const t = useTranslate();
    usePageTitle(t('business_page_title'));

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return (
        <>
        <Navbar/>
        <HeaderEmpresa title_empresa="//Somos a Nortem" subtitle_empresa1="Somos uma consultoria empresarial completa, dedicada a" subtitle_empresa2="auxiliar empresas de todos os portes. Com uma equipe" subtitle_empresa3="multidisciplinar e experiente, oferecemos soluções" subtitle_empresa4="integradas para revitalizar e promover o crescimento" subtitle_empresa5="sustentável dos nossos clientes. Utilizamos diversas" subtitle_empresa6="ferramentas para enfrentar e resolver os mais variados" subtitle_empresa7="desafios empresariais."/>
        <GrupoNortem/>
        <Group/>
        <Cidades/>
        <Footer2/>
        </>
    )
}