import React, { useEffect, useState } from "react";
import '../Modal/Modal.css'
import Video from '../Back-Ground-Video/Video'
import Icon1 from '../../../assets/img/Icons-Diferenciais/01.png';
import Icon2 from '../../../assets/img/Icons-Diferenciais/02.png';
import Icon3 from '../../../assets/img/Icons-Diferenciais/03.png';
import Line from '../../../assets/img/Line-Diferenciais/Line 1.png';
import LineModal from '../../../assets/img/Line-Modal/Line-Modal.png'
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { sendWhatsAppMessage, useTranslate } from "../../../helpers/helpers";
import { motion } from "framer-motion";


function CardsServices() {

    const [selectedService, setSelectedService] = useState(null);

  

    // Função para definir o serviço selecionado ao clicar no botão
    const handleServiceClick = (service) => {
        setSelectedService(service);
    };

    // Função para fechar o modal
    const closeModal = () => {
        setSelectedService(null);
    };

    return (
        <section>
            <div className="container">
                <div className="row p-2 justify-content-sm-start row-cards-services">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        
                            <motion.div 
                            whileHover={{ scale: 0.9 }}
                            onHoverStart={e => {}}
                            onHoverEnd={e => {}}
                            className="card-body d-flex flex-column card-services">
                                <img className="icon1-services ms-3 mt-3 align-self-start" src={Icon1} alt="" />

                                <h2 className="title-card1-services text-xl xl:text-2xl 2xl:text-3xl ms-3 mt-5 text-white">Nome do serviço 1</h2>

                                <h6 className="subtitle-card1-services text-sm ms-3 xl:text-lg text-white">Descrição breve sobre o serviço<br/> fornecido</h6>

                                <img className="img-fluid me-5 mt-5 line-services" src={Line} alt="" />

                                <button 
                                    className="btn-ligh mt-3 mb-3 btn-card1-services" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#serviceModal" 
                                    onClick={() => handleServiceClick()}
                                >
                                    Conhecer
                                </button>
                            </motion.div>
                        
                    </div>

                    <motion.div 
                    whileHover={{ scale: 0.9 }}
                    onHoverStart={e => {}}
                    onHoverEnd={e => {}}
                    className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        
                            <div className="card-body d-flex flex-column card-services" data-aos="zoom-out">
                                <img className="icon1-services ms-3 mt-3 align-self-start" src={Icon2} alt="" />

                                <h2 className="title-card1-services text-xl xl:text-2xl 2xl:text-3xl ms-3 mt-5 text-white">Nome do serviço 2</h2>

                                <h6 className="subtitle-card1-services text-sm xl:text-lg ms-3 text-white">Descrição breve sobre o serviço<br/> fornecido</h6>

                                <img className="img-fluid me-5 mt-5 line-services" src={Line} alt="" />

                                <button 
                                    className="btn-ligh mt-3 mb-3 btn-card1-services" 
                                    data-bs-toggle="modal" 
                                    data-bs-target="#serviceModal" 
                                    onClick={() => handleServiceClick()}
                                >
                                    Conhecer
                                </button>
                            </div>
                        
                    </motion.div>
                </div>
            </div>

            {/* Modal */}
            <div className="modal fade" id="serviceModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{selectedService}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                        </div>
                        <div className="modal-body">

                            <h2 className="um ms-4">//01</h2>

                            <h1 className="title-modal ms-4 mt-5">Suporte Legal</h1>

                            <h6 className="subtitle-modal ms-4 mb-5 mt-4">A Nortem oferece serviços completos de<br/> assessoria jurídica para empresas de todos os <br/> portes. Com uma equipe de advogados<br/> experientes, garantem suporte especilizado<br/> em diversas áreas do direito empresarial,<br/> assegurando que sua empresa esteja sempre<br/> em conformidade com as regulamentações e <br/> preparada para enfrentar qualquer desafio<br/> jurídico.</h6>

                            <img className="img-fluid w-50 line-modal" src={LineModal} alt="" />

                            <h1 className="title-modal2 ms-5 mt-3">Etapas</h1>

                            <div className="container mt-5">
                                <div className="row text-center">
                                    <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                        <div className="container d-flex flex-column align-items-center">    
                                            <h2 className="circle">01</h2>
                                            <h5 className="title-circle">Aceite na proposta comercial.</h5>
                                            <h6 className="subtitle-circle">Envio de documentos por<br/> e-mail configura o aceite da<br/> proposta.</h6>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                         <div className="container d-flex flex-column align-items-center">
                                            <h2 className="circle">02</h2>
                                            <h5 className="title-circle">Aceite na proposta comercial</h5>
                                            <h6 className="subtitle-circle">Envio de documentos por<br/> e-mail configura o aceite da<br/> proposta.</h6>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                        <div className="container d-flex flex-column align-items-center">
                                            <h2 className="circle">03</h2>
                                            <h5 className="title-circle">Aceite na proposta comercial</h5>
                                            <h6 className="subtitle-circle">Envio de documentos por<br/> e-mail configura o aceite da<br/> proposta.</h6>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                                        <div className="container d-flex flex-column align-items-center">
                                            <h2 className="circle">04</h2>
                                            <h5 className="title-circle">Aceite na proposta comercial</h5>
                                            <h6 className="subtitle-circle">Envio de documentos por<br/> e-mail configura o aceite da<br/> proposta.</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <img className="img-fluid w-50 mt-5 line-modal" src={LineModal} alt="" />

                            <h1 className="title-modal ms-5 mt-5">Saiba um pouco mais</h1>

                            <div className="container mt-5">
                                <div className="row align-items-center justify-cotent-center">
                                    <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6 my-4">
                                        <Video/>
                                    </div>

                                    <div className="col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                      <Video/>
                                    </div>

                                    <div className="container mt-5">
                                        <motion.button 
                                        whileHover={{ scale: 0.9 }}
                                        onHoverStart={e => {}}
                                        onHoverEnd={e => {}}
                                        className="btn-modal"
                                        style={{height: '6vh'}}
                                        onClick={() => sendWhatsAppMessage()}>Conhecer
                                        </motion.button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}></button>
                            <button type="button" className="btn btn-primary"></button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
npm}

export default CardsServices;