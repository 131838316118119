import React, { useEffect, useState } from "react";
import Logo2 from '../../assets/img/Logo/Logo2.png';
import Logo from '../../assets/img/Logo/Logo.png';
import BandeiraUSA from "../../assets/bandeiras/united-states.png";
import BandeiraBR from "../../assets/bandeiras/brasil.png";
import './Navbar.css';
import { Link, useLocation } from "react-router-dom";
import Flag from "../Flags/Flag";
import { useTranslate } from "../../helpers/helpers";

function Navbar() {
    const { pathname } = useLocation(); // Obtém o caminho atual
    const t = useTranslate();

    const [navSize, setNavSize] = useState('150px'); // Tamanho inicial da navbar
    const [navOpacity] = useState(1); // Opacidade inicial da navbar
    const [logoClip, setLogoClip] = useState('inset(0 0 0 0)'); // Define a parte visível do logo
    const [navBackground, setNavBackground] = useState('transparent'); // Fundo inicial da navbar

    const isMobile = window.innerWidth <= 768; // Definir como mobile se a largura da tela for menor ou igual a 768px
    const logoHeightMobile = '20px'; // Defina a altura para mobile
    const logoHeightDesktop = '30px'; // Defina a altura para desktop
    const toCutDesktop = 220;
    const toCutMobile = 150;

    // Função para ajustar o tamanho da navbar e o "corte" do logo ao fazer scroll
    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const vh70 = window.innerHeight * 0.75; // Calcula 70% da altura da viewport (70vh)

        // Ajusta o fundo da navbar apenas na rota "/"
        if (pathname === '/') {
            if (scrollPosition > vh70) {
                setNavBackground('rgba(0, 0, 0, 1)'); // Adiciona o fundo preto ao ultrapassar 70vh na rota "/"
            } else {
                setNavBackground('transparent'); // Volta ao fundo transparente abaixo de 70vh na rota "/"
            }
        } else {
            setNavBackground('rgba(0, 0, 0, 0)'); // Para outras rotas, o fundo é sempre preto
        }

        if (scrollPosition > 50) {
            setNavSize('100px'); // Diminui o tamanho da navbar ao rolar a página
        } else {
            setNavSize('150px'); // Tamanho original quando a página está no topo
        }

        // Ajusta o clip-path do logo dinamicamente na página inicial
        if (pathname === '/') {
            const newClip = Math.min(isMobile ? toCutMobile : toCutDesktop, scrollPosition) + 'px'; // Define o quanto o logo será "cortado" ao rolar
            setLogoClip(`inset(0 ${newClip} 0 0)`); // Aplica o efeito de corte na parte superior do logo
        }
    };

   
    const getLogoProps = () => {

        if (pathname === '/') {
            return {
                logoSrc: Logo,
                logoClass: 'logo-header',
                logoColor: 'white',
                linkClass: 'nav-link text-offcanvas text-white',
                linkClassMobile: 'nav-link text-offcanvas text-dark',
                logoHeight: isMobile ? logoHeightMobile : logoHeightDesktop // Altera a altura do logo com base no tamanho da tela
            };
        } else {
            return {
                logoSrc: Logo2,
                logoClass: 'logo-header w-50',
                logoColor: 'black',
                linkClass: 'nav-link text-offcanvas text-dark',
                linkClassMobile: 'nav-link text-offcanvas text-dark',
                logoHeight: 'auto' // Usa a altura auto se não for mobile
            };
        }
    };

    const { logoSrc, logoClass, linkClass, linkClassMobile, logoHeight, logoColor } = getLogoProps();

    return (
        <section>
           <nav
                className="navbar navbar-expand-md bg-body-transparent"
                style={{
                    position: 'fixed', // Mantém o navbar fixo
                    top: 0,
                    width: '100%',
                    height: navSize, // Altura dinâmica baseada no scroll
                    transition: 'all 0.3s ease', // Transição suave para mudanças
                    opacity: navOpacity, // Opacidade ajustável com o scroll
                    zIndex: 3,
                    backgroundColor: navBackground // Fundo ajustável com base no scroll
                }}
            >
                <div className="container-fluid mx-5 no-gutters">
                    <a className="navbar-brand" href="#">
                        <img
                            className={logoClass}
                            src={logoSrc}
                            alt="Logo"
                            style={{
                                width: 'auto', // Mantém a largura automática
                                height: logoHeight, // Ajusta a altura dinamicamente
                                clipPath: logoClip, // Aplica o "corte" ao logo com base no scroll
                                transition: 'clip-path 0.3s ease', // Transição suave para o efeito de corte
                                filter: pathname === '/' ? 'brightness(0) invert(1)' : 'none' // Aplica o efeito de cor branca na logo na página inicial
                            }}
                        />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle Navigation" style={{ border: 'none' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" className="size-6" style={{ width: '32px', height: '32px', color: logoColor }}>
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </button>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">{t('menu')}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label={t('close')}></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item">
                                    <Link to="/" className={isMobile ? linkClassMobile : linkClass}>{t('home')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/empresa" className={isMobile ? linkClassMobile : linkClass}>{t('company')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/solucoes" className={isMobile ? linkClassMobile : linkClass}>{t('solutions')}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contato" className={isMobile ? linkClassMobile : linkClass}>{t('contact')}</Link>
                                </li>
                                <div className="d-flex ms-lg-5 mt-sm-5 mt-md-1 ">
                                    <Flag image={BandeiraBR} language='pt' />
                                    <Flag image={BandeiraUSA} language='en' />
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </section>
    );
}

export default Navbar;