import React from "react";
import { useEffect } from "react";
import Footer2 from '../Components/Footer2/Footer'
import Header from "../Components/Header/Header";
import Navbar from "../Components/Navbar/Navbar";
import Trust from '../Components/Trust/Trust';
import About from '../Components/About/About';
import Diferenciais from '../Components/Diferenciais/Diferenciais';
import GrupoNortem from '../Components/Grupo-Nortem/GrupoNortem';
import History from '../Components/History-Nortem/History';
import { usePageTitle, useTranslate } from '../helpers/helpers';
import Services from "../Components/Services/Services";


export default function Inicio() {
    const t = useTranslate();
    usePageTitle(t('home_page_title'));

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return (
        <>
            <Header />
            <Trust />
            <About />
            <Diferenciais />
            <Services />
            <GrupoNortem />
            <History />
            <Footer2/> 
        </>
    )
}