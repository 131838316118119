import React from "react";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../helpers/helpers";

const Flag = (props) => {
    const { i18n } = useTranslation(); // Obtém o idioma atual

    const isSelected = i18n.language === props.language;

    return (
        <button
            style={{
                backgroundColor: 'transparent',
                outline: 'none',
                boxShadow: 'none',
                overflow: 'hidden',
                objectFit: 'cover',
                border: 'none',
                cursor: 'pointer'
            }}
            onClick={() => changeLanguage(props.language)} // Usa a prop `language` para definir o idioma
        >
            <img
                loading="lazy"
                className="mx-2"
                src={props.image} // Usa a prop `image` para definir a imagem da bandeira
                style={{
                    borderRadius: '50%', // Adiciona bordas arredondadas
                    width: isSelected ? '40px' : '33px', // Aumenta o tamanho se for selecionado
                    height: 'auto', // Mantém a proporção da imagem
                    border: isSelected ? '1px solid #e91e63' : 'none', // Aplica borda rosa (#e91e63) se for o idioma selecionado
                    transition: 'all 0.3s ease', // Transição suave para o efeito de aumento
                }}
                alt={`Flag for ${props.language}`} // Alt text para acessibilidade
            />
        </button>
    );
}

export default Flag;