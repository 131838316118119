import './App.css';
import Inicio from './Pages/Inicio';
import Empresa from './Pages/Empresa';
import Solucoes from "./Pages/Solucoes";
import Contato from './Pages/Contato';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import ScrollToTopButton from './Components/ScrollToTopButton/ScrollToTopButton';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/empresa" element={<Empresa />} />
        <Route path="/solucoes" element={<Solucoes />} />
        <Route path="/contato" element={<Contato />} />
      </Routes>
      {/* <ScrollToTopButton /> */}
    </BrowserRouter>
  );
}

export default App;