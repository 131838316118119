import React from "react";
import { useEffect } from "react";
import { sendWhatsAppMessage, useTranslate } from "../../helpers/helpers";
import Logo2 from '../../assets/img/Logo-Footer/nortem branco.png'
import Line from '../../assets/img/Line-Footer/Line-Footer.png'
import Icon1 from '../../assets/img/Icons-Footer/Instagram.png'
import Icon2 from '../../assets/img/Icons-Footer/Facebook.png'
import Icon3 from '../../assets/img/Icons-Footer/Youtube.png'
import './Footer.css'; 
import { motion } from "framer-motion";

const Footer = () => {

  const t = useTranslate();



  return (
    <div className="container-fluid bck">
  <footer className="text-center cont-footer text-lg-start pt-4">
    {/* Grid container */}
    <div className="container-fluid d-flex flex-column mb-5">
      <img className="line" src={Line} alt="" />

      <img className="logo-footer mt-5 ms-sm-5 ms-md-5 ms-lg-5 ms-xl-5 ms-xxl-5" src={Logo2} alt="" />
    </div>

    <div className="container p-4">
      {/*Grid row*/}
      <div className="row row-footer2">
        {/*Grid column*/}
        <div className="col-lg-4 col-md-12 mb-4 mb-lg-0 coluna1">
          <h5 className="text-uppercase mb-4 text fs-5 fw-bold text-white mt-1">{t('footer_come_see_us')}</h5>

          <p className="text fs-7 my-2">{t('footer_address_street')}<br/>{t('footer_address_neighborhood')}</p>

          <p className="text fs-7 my-2">{t('footer_address_city')}</p>

          <p className="text fs-7">{t('footer_address_zipcode')}</p>
        </div>
        {/*Grid column*/}

        {/*Grid column*/}
        <div className="col-lg-4 col-md-12 mb-4 mb-lg-0 coluna2">
          <h5 className="text-uppercase mb-4 text fs-5 fw-bold text-white">{t('footer_social_title')}</h5>

          <div className="flex justify-center lg:justify-start">
            <a href="https://www.instagram.com/grupo_nortem/">
            <img src={Icon1} alt="" />
            </a>
            <img className="ms-4" src={Icon2} alt="" />
            <img className="ms-4" src={Icon3} alt="" />
          </div>
        </div>
        {/*Grid column*/}

        {/*Grid column*/}
        
        {/*Grid column*/}

        {/*Grid column*/}
        <div className="col-lg-4 col-md-12 mb-4 mb-lg-0 text-xxl-end coluna3">
          <h5 className="text-uppercase mb-4 text-white text fs-5 fw-bold">{t('footer_specialized')}</h5>

            <p className="text fs-7 mt-xxl-5">SAC: 0800 - XXX - XXX</p>
            <motion.button 
            whileHover={{ scale: 0.9 }}
            onHoverStart={e => {}}
            onHoverEnd={e => {}}
            className="btn-footer text-black bg-white" onClick={() => sendWhatsAppMessage()}>{t('footer_contact_placeholder')}
            </motion.button>

        </div>
        {/*Grid column*/}
      </div>
      {/*Grid row*/}
    </div>
    {/* Grid container */}

    {/* Copyright */}
    <div className="text-center mt-5 p-3 border-top border-black text text-white">
      {t('footer_rights')}<br/>
      <p className="text-white">{t('footer_dev')}</p>
    </div>
    {/* Copyright */}
  </footer>
</div>
  );
};

export default Footer;