import React from "react";
import { useEffect } from "react";
import Navbar from '../Components/Navbar/Navbar'
import HeaderEmpresa from '../Components/Header-Empresa/HeaderEmpresa'
import HeaderSolucoes from '../Components/Header-Solucoes/HeaderSolucoes'
import Services from '../Components/Services-Nortem/Services'
import Footer2 from '../Components/Footer2/Footer'
import { usePageTitle, useTranslate } from '../helpers/helpers';

export default function Solucoes(){
    const t = useTranslate();
    usePageTitle(t('solutions_page_title'));

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
        <Navbar/>
        <HeaderSolucoes/>
        <Services/>
        <Footer2/>
        </>
    )
}

