import React, { useEffect } from "react";
import InfoContatoCSS from './InfoContato.css';
import Navbar from '../Navbar/Navbar';
import { sendWhatsAppMessage, useTranslate } from "../../helpers/helpers";
import { motion } from "framer-motion";

function InfoContato() {
    const t = useTranslate();

    
    
    return (
        <section>
            <div className="container-fluid">
                <div className="row align-items-center justify-content-start row-info">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-info">
                        <div className="container">
                            <h4 className="title-info" >{t('footer_come_see_us')}</h4>
                            <h6 className="subtitle-info lh-lg" >{t('footer_address_street')} <br/> {t('footer_address_city')}<br/>{t('footer_address_zipcode')}</h6>

                            <h4 className="title-info mt-4" >{t('contact_phone')}</h4>
                            <h6 className="subtitle-info lh-base" >+55 (83) 9XXXX-XXXX</h6>

                            <h4 className="title-info mt-4" >{t('button_know')}</h4>
                            <h6 className="subtitle-info lh-base" >contato@gruponortem.com</h6>

                            <motion.button 
                            whileHover={{ scale: 1.1 }}
                            whileTap={{scale: 0.9}}
                            onHoverStart={e => {}}
                            onHoverEnd={e => {}}
                            className="mt-4 btn-info" onClick={() => sendWhatsAppMessage()} data-aos="fade-right">{t('footer_contact_placeholder')}
                            </motion.button>
                        </div> 
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="card">
                            <div className="card-body card-info">
                                <div className="container">
                                    <form>
                                        <div className="form-group p-3">
                                            <label className="forms" htmlFor="formGroupExampleInput" >{t('form_name_label')}</label>
                                            <input type="text"  className="form-control" id="formGroupExampleInput"/>

                                            <label className="forms mt-5" htmlFor="formGroupExampleInput" >{t('form_email_label')}</label>
                                            <input type="email" className="form-control" id="formGroupExampleInput" />

                                            <label className="forms mt-5" htmlFor="formGroupExampleInput" >{t('form_phone_label')}</label>
                                            <input type="number" className="form-control" id="formGroupExampleInput" />

                                            <label className="forms mt-5" htmlFor="exampleFormControlTextarea1" >{t('form_message_label')}</label>
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" ></textarea>

                                            <motion.button 
                                            whileHover={{ scale: 0.9 }}
                                            onHoverStart={e => {}}
                                            onHoverEnd={e => {}}
                                            className="btn-forms w-100 mt-5" >{t('form_submit_button')}</motion.button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </section>
    );
}

export default InfoContato;