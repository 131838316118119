const pt = {
  translation: {
    home_page_title: "Grupo Nortem | Início",
    business_page_title: "Grupo Nortem | Empresa",
    solutions_page_title: "Grupo Nortem | Soluções",
    contact_page_title: "Grupo Nortem | Contato",
    menu: "Menu",
    home: "Início",
    company: "Empresa",
    solutions: "Soluções",
    contact: "Contato",
    titleHeader: "Revitalize sua empresa e alcance um crescimento sustentável conosco.",
    subtitleHeader: "Nossa equipe experiente oferece soluções integradas para superar qualquer desafio empresarial.",
    buttonText: "Vamos crescer juntos!",
    trust_title: "Quem confia na Nortem?",
    trust_subtitle: "Veja um pouco de quem está conosco",
    about_impostos: "+5 Bi",
    about_dividas: "+50 Bi",
    about_projetos: "+500",
    subtitle_impostos: "de impostos recuperados",
    subtitle_dividas: "de dívidas reestruturadas",
    subtitle_projetos: "projetos",
    diferencial_moderna: "+MODERNA",
    diferencial_segura: "+SEGURA",
    diferencial_inovadora: "+INOVADORA",
    services_title: "Temos o serviço ideal para você",
    card1_title: "Assessoria <br />Contábil",
    card1_subtitle: "Planejamento tributário",
    card2_title: "Assessoria <br />Jurídica",
    card2_subtitle: "Reestruturação empresarial",
    card3_title: "Captação de <br />Recursos",
    card3_subtitle: "Banco",
    button_know: "Conhecer",
    button_view_all: "Veja tudo que podemos oferecer para sua empresa",
    history_title: "Fizeram história juntos com a Nortem",
    history_subtitle: "Veja um pouco de quem faz história conosco",
    history_button: 'Faça parte dessa história',
    join_history_button: "Faça parte dessa história",
    footer_specialized: "Especializados em empresas",
    footer_subtitle: "Recuperando empresas e crescendo",
    footer_phone: "Telefone: (83) 9 XXXX-XXXX",
    footer_contact_placeholder: "Fale com a gente",
    footer_rights: "© 2024 Todos os Direitos Reservados | CNPJ: xx.xxx.xxxx/xxxx-xx",
    footer_dev: "Desenvolvido por Flag Lab",
    footer_come_see_us: "Venha nos ver",
    footer_address_street: "R. Abelardo da Silva Guimarães Barreto, 51",
    footer_address_neighborhood: "Altiplano",
    footer_address_city: "João Pessoa - PB",
    footer_address_zipcode: "CEP: 58046-005",
    footer_social_title: "Encontre-nos nas redes sociais",
    empresa_header_title: "//Somos a Nortem",
    empresa_header_subtitle: "Somos uma consultoria empresarial completa, dedicada a auxiliar empresas de todos os portes. Com uma equipe multidisciplinar e experiente, oferecemos soluções integradas para revitalizar e promover o crescimento sustentável dos nossos clientes. Utilizamos diversas ferramentas para enfrentar e resolver os mais variados desafios empresariais.",
    empresa_analysis_text: "Realizamos análises profundas para entender os desafios das empresas, definimos objetivos claros e desenvolvemos estratégias personalizadas. Promovemos inovação e melhoria contínua, garantindo crescimento sustentável e resultados tangíveis. Trabalhamos lado a lado com a equipe da empresa, oferecendo soluções jurídicas, contábeis e financeiras integradas para atender de forma eficaz e inovadora as demandas do mercado empresarial.",
    cidade_title: "//Brasil & Mundo",
    cidade_nortem_sp: "Nortem",
    cidade_sp: "São Paulo",
    cidade_nortem_pb: "Nortem",
    cidade_pb: "Paraíba",
    solucoes_header_title: "//Sua melhor opção",
    solucoes_header_subtitle: "Oferecemos soluções para muitos ramos",
    services_accounting_title: "//01 Assessoria Contábil",
    services_accounting_subtitle: "Planejamento Tributário",
    services_legal_title: "//02 Assessoria Jurídica",
    services_legal_subtitle: "Reestruturação Empresarial",
    services_funding_title: "//03 Captação de Recursos",
    services_funding_subtitle: "Banco discreto",
    form_title: "//Vamos falar?",
    form_name_label: "Qual o seu nome?",
    form_email_label: "E-mail para falarmos",
    contact_phone: "Telefone",
    form_phone_label: "Melhor telefone",
    form_message_label: "Fale um pouco como podemos te ajudar",
    form_submit_button: "Enviar"
  }
};

export default pt;