import React, { useEffect } from "react";
import CidadesCSS from './Cidades.css';
import SP from '../../assets/img/Cidades-Group/SP.png';
import PB from '../../assets/img/Cidades-Group/PB.png';
import { useTranslate } from "../../helpers/helpers";

function Cidade() {
    const t = useTranslate();



    return (
        <section className="cidades">
                <div className="flex flex-col itesm-center justify-center text-center mt-20 lg:mt-72">
                    <h1 className="text-4xl lg:text-5xl 2xl:text-6xl font-bold text-black">{t('cidade_title')}</h1>
                </div>

                <div className="flex flex-col items-center justify-center text-center lg:grid lg:grid-cols-2 mt-20">
                    <div className="lg:flex lg:flex-col lg:items-center">
                        <img className="px-10 max-w-sm md:max-w-xl lg:max-w-lg 2xl:max-w-2xl" src={SP} alt="" />

                        <h1 className="text-black font-bold text-3xl mt-10">{t('cidade_nortem_sp')}</h1>
                        <h1 className="text-black font-normal text-xl">{t('cidade_sp')}</h1>
                    </div>

                    <div className="lg:flex lg:flex-col lg:items-center">
                        <img className="px-10 max-w-sm md:max-w-xl lg:max-w-lg 2xl:max-w-2xl lg:px-14 2xl:px-16" src={PB} alt="" />

                        <h1 className="text-black font-bold text-3xl mt-10">{t('cidade_nortem_pb')}</h1>
                        <h1 className="text-black font-normal text-xl">{t('cidade_pb')}</h1>
                    </div>
                </div>

                
        </section>
    );
}

export default Cidade;

{/* <div className="container-fluid">
<div className="row align-items-start row-cidades">
    <div className="col-xxl-12 d-flex my-5 flex-column text-center">
        <div className="container-fluid">
            <h1 className="title-cidades mb-5 mt-5" >{t('cidade_title')}</h1>

            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                <div className="container mt-5">
                    <img className="ms-xxl-5 img-sp img-fluid"  src={SP} alt="São Paulo"/>
                    <h5 className="nortem mt-5" data-aos="fade-right">{t('cidade_nortem_sp')}</h5>
                    <h6 className="cidades" data-aos="fade-right">{t('cidade_sp')}</h6>
                </div>

                <div className="container mt-5">
                    <img className="ms-xxl-5 img-pb img-fluid"  src={PB} alt="Paraíba"/>
                    <h5 className="nortem mt-5" data-aos="fade-right">{t('cidade_nortem_pb')}</h5>
                    <h6 className="cidades" data-aos="fade-right">{t('cidade_pb')}</h6>
                </div>
            </div>

        </div>
    </div>
</div>
</div> */}