import React, { useEffect } from "react";
import DiferenciaisCSS from './Diferenciais.css';
import { useTranslate } from "../../helpers/helpers";

function Diferenciais() {
    const t = useTranslate();

  
    return (
        <section className="diferenciais">
            <div className="flex flex-col items-center justify-center" style={{height: '70vh'}}>
                <div>
                    <span className="text-black font-black text-4xl sm:text-5xl lg:text-6xl" style={{fontFamily: 'Anek Latin,sans'}}>{t('diferencial_moderna')}</span><br />
                    <span className="text-black font-black text-4xl sm:text-5xl lg:text-6xl" style={{fontFamily: 'Anek Latin,sans'}}>{t('diferencial_segura')}</span><br />
                    <span className="text-black font-black text-4xl sm:text-5xl lg:text-6xl" style={{fontFamily: 'Anek Latin,sans'}}>{t('diferencial_inovadora')}</span>
                </div>
            </div>
        </section>
    );
}

export default Diferenciais;

