import React from "react";
import { useEffect } from "react";
import { usePageTitle, useTranslate } from '../helpers/helpers';
import HeaderContato from '../Components/Header-Contato/HeaderContato'
import InfoContato from '../Components/Info-Contato/InfoContato'
import Footer2 from '../Components/Footer2/Footer'
import Navbar from "../Components/Navbar/Navbar";

export default function Contato(){
    const t = useTranslate();
    usePageTitle(t('contact_page_title'));


     useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <>
        <Navbar/>
        <HeaderContato/>
        <InfoContato/>
        <Footer2/>
        </>
    )
}