import React, { useEffect } from "react";
import ServicesCSS from './Services.css'
import { Link } from "react-router-dom";
import Icon1 from "../../assets/img/Icons-Diferenciais/01.png";
import Icon2 from "../../assets/img/Icons-Diferenciais/02.png";
import Icon3 from "../../assets/img/Icons-Diferenciais/03.png";
import Line from '../../assets/img/Line-Diferenciais/Line 1.png';
import { useTranslate } from "../../helpers/helpers";
import { motion } from "framer-motion";

function Services() {
    const t = useTranslate();

 

    return (
        <section>
            <div className="container">
                <div className="row justify-content-center justify-content-md-start row-cards-dife">

                    <div className="container">
                        <div className="col-xxl text-center">
                            <h6 className="display-5 mb-5 fw-bold text-black title">{t('services_title')}</h6>
                        </div>
                    </div>

                    <div className="col-md-6 col-xl-6 col-xxl-4">
                        
                            <motion.div 
                            whileHover={{ scale: 0.9 }}
                            onHoverStart={e => {}}
                            onHoverEnd={e => {}}
                            className="card-body card1-dife d-flex flex-column" data-aos="zoom-out">
                                <h6 className="icon1-services ms-3 mt-4 text-white">//01</h6>
                                <h6 className="fw-bold title-card1 ms-3 mt-5 text-white" dangerouslySetInnerHTML={{ __html: t('card1_title') }}></h6>
                                <h5 className="subtitle-card1 text-white ms-3">{t('card1_subtitle')}</h5>
                                <img className="line-card1 img-fluid w-50 ms-3 mt-4" src={Line} alt="Line" />
                                <button className="btn-card-services text-black bg-white mt-5 mb-3 mx-3">{t('button_know')}</button>
                            </motion.div>
                        
                    </div>

                    <div className="col-md-6 col-xl-6 col-xxl-4">
                    
                            <motion.div 
                            whileHover={{ scale: 0.9 }}
                            onHoverStart={e => {}}
                            onHoverEnd={e => {}}
                            className="card-body card2-dife d-flex flex-column" data-aos="zoom-out">
                                <h6 className="icon2-services text-white ms-3 mt-4">//02</h6>
                                <h6 className="fw-bold title-card1 text-white ms-3 mt-5" dangerouslySetInnerHTML={{ __html: t('card2_title') }}></h6>
                                <h5 className="subtitle-card1 text-white ms-3">{t('card2_subtitle')}</h5>
                                <img className="line-card1 img-fluid w-50 ms-3 mt-4" src={Line} alt="Line" />
                                <button className="btn-card-services text-black bg-white mt-5 mb-3 mx-3">{t('button_know')}</button>
                            </motion.div>
                        
                    </div>

                    <div className="col-md-6 col-xl-6 col-xxl-4  mb-4">
                        
                            <motion.div 
                            whileHover={{ scale: 0.9 }}
                            onHoverStart={e => {}}
                            onHoverEnd={e => {}}
                            className="card-body card3-dife d-flex flex-column" data-aos="zoom-out">
                                <h6 className="icon3-services ms-3 mt-4 text-white">//03</h6>
                                <h6 className="fw-bold title-card1 text-white ms-3 mt-5" dangerouslySetInnerHTML={{ __html: t('card3_title') }}></h6>
                                <h5 className="subtitle-card1 text-white ms-3">{t('card3_subtitle')}</h5>
                                <img className="line-card1 img-fluid w-50 ms-3 mt-4" src={Line} alt="Line" />
                                <button className="btn-card-services text-black bg-white mt-5 mb-3 mx-3">{t('button_know')}</button>
                            </motion.div>
                        
                    </div>

                    <div className="container text-center">
                        <Link to="/solucoes">
                            <motion.button 
                            whileHover={{ scale: 1.1 }}
                            whileTap={{scale: 0.9}}
                            onHoverStart={e => {}}
                            onHoverEnd={e => {}}
                            className="btn-outside text-black text-sm">{t('button_view_all')}</motion.button>
                        </Link>
                    </div>

                   
                    
                </div>
            </div>
        </section>
    );
}

export default Services;