const en = {
    translation: {
      home_page_title: "Grupo Nortem | Home",
      business_page_title: "Grupo Nortem | Company",
      solutions_page_title: "Grupo Nortem | Solutions",
      contact_page_title: "Grupo Nortem | Contact",
      menu: "Menu",
      home: "Home",
      company: "Company",
      solutions: "Solutions",
      contact: "Contact",
      titleHeader: "Revitalize your company and achieve sustainable growth with us.",
      subtitleHeader: "Our experienced team offers integrated solutions to overcome any business challenge.",
      buttonText: "Let's grow together!",
      trust_title: "Who trusts Nortem?",
      trust_subtitle: "See a little of who is with us",
      about_impostos: "+5 Bi",
      about_dividas: "+50 Bi",
      about_projetos: "+500",
      subtitle_impostos: "in taxes recovered",
      subtitle_dividas: "in restructured debts",
      subtitle_projetos: "projects",
      diferencial_moderna: "+MODERN",
      diferencial_segura: "+SECURE",
      diferencial_inovadora: "+INNOVATIVE",
      services_title: "We have the ideal service for you",
      card1_title: "Accounting <br />Advisory",
      card1_subtitle: "Tax planning",
      card2_title: "Legal <br />Advisory",
      card2_subtitle: "Business restructuring",
      card3_title: "Funding <br />Resources",
      card3_subtitle: "Bank",
      button_know: "Learn More",
      button_view_all: "See everything we can offer your company",
      history_title: "Made history together with Nortem",
      history_subtitle: "See a little of who makes history with us",
      history_button: 'Be part of that history',
      join_history_button: "Be part of this history",
      footer_specialized: "Specialized in companies",
      footer_subtitle: "Recovering companies and growing",
      footer_phone: "Phone: (83) 9 XXXX-XXXX",
      footer_contact_placeholder: "Talk to us",
      footer_rights: "© 2024 All Rights Reserved | CNPJ: xx.xxx.xxxx/xxxx-xx",
      footer_dev: "Developed by Flag Lab",
      footer_come_see_us: "Come see us",
      footer_address_street: "R. Abelardo da Silva Guimarães Barreto, 51",
      footer_address_neighborhood: "Altiplano",
      footer_address_city: "João Pessoa - PB",
      footer_address_zipcode: "ZIP Code: 58046-005",
      footer_social_title: "Find us on social networks",
      empresa_header_title: "//We are Nortem",
      empresa_header_subtitle: "We are a full business consulting firm, dedicated to helping companies of all sizes. With a multidisciplinary and experienced team, we offer integrated solutions to revitalize and promote the sustainable growth of our clients. We use various tools to face and solve the most varied business challenges.",
      empresa_analysis_text: "We conduct in-depth analyses to understand business challenges, set clear goals, and develop personalized strategies. We promote innovation and continuous improvement, ensuring sustainable growth and tangible results. We work side by side with the company's team, offering integrated legal, accounting, and financial solutions to meet the demands of the business market effectively and innovatively.",
      cidade_title: "//Brazil & World",
      cidade_nortem_sp: "Nortem",
      cidade_sp: "São Paulo",
      cidade_nortem_pb: "Nortem",
      cidade_pb: "Paraíba",
      solucoes_header_title: "//Your best option",
      solucoes_header_subtitle: "We offer solutions for many industries",
      services_accounting_title: "//01 Accounting Advisory",
      services_accounting_subtitle: "Tax Planning",
      services_legal_title: "//02 Legal Advisory",
      services_legal_subtitle: "Business Restructuring",
      services_funding_title: "//03 Funding",
      services_funding_subtitle: "Discrete Bank",
      form_title: "//Let's talk?",
      form_name_label: "What is your name?",
      form_email_label: "Email to contact you",
      contact_phone: "Phone",
      form_phone_label: "Best phone number",
      form_message_label: "Tell us how we can help you",
      form_submit_button: "Send"
    }
  };
  
  export default en;