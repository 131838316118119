import React, { useEffect } from "react";
import ServicesCSS from './Services.css';
import CardsServices from './Cards-Services/CardsServices';
import { useTranslate } from "../../helpers/helpers";

function Services(props) {
    const t = useTranslate();

    return (
        <section>
            <div className="container">
                <div className="row d-flex align-items-center justify-content-center row-services">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className="container d-flex p-4 flex-column">
                            <h1 className="title-services" data-aos="fade-right">{t('services_accounting_title')}</h1>
                            <h5 className="subtitle-services" data-aos="fade-right">{t('services_accounting_subtitle')}</h5>
                        </div>
                        <CardsServices />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5">
                        <div className="container d-flex p-4 flex-column align-items-end">
                            <h1 className="title-services" data-aos="fade-right">{t('services_legal_title')}</h1>
                            <h5 className="subtitle-services" data-aos="fade-right">{t('services_legal_subtitle')}</h5>
                        </div>
                        <CardsServices />
                        <CardsServices />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5">
                        <div className="container d-flex p-4 flex-column">
                            <h1 className="title-services" data-aos="fade-right">{t('services_funding_title')}</h1>
                            <h5 className="subtitle-services" data-aos="fade-right">{t('services_funding_subtitle')}</h5>
                        </div>
                        <CardsServices />
                    </div>

                    <div className="x">

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;