import React from "react";
import GrupoNortemCSS from './GrupoNortem.css'
import { useEffect } from 'react'
import NortemMockup from '../../assets/img/Grupo-Nortem/Nortem.png'

function GrupoNortem () {
    return (
        <section>
            <div className="container-fluid">
                <div className="row d-flex align-items-center row-grupo-nortem" style={{backgroundImage: `url(${NortemMockup})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', position: 'relative'}}>
                    
                <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0)', // Preto com 50% de opacidade
                            zIndex: 1
                        }}
                    ></div>

                </div>
            </div>
        </section>
    )
}

export default GrupoNortem;