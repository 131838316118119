import React from "react";
import GroupCSS from './Group.css'
import GroupImg from '../../assets/img/Group/Group-Component.png'
import { useEffect } from 'react'
import { useTranslate } from "../../helpers/helpers";


function Group() {
    const t = useTranslate();


    return (
        <section className="group">
           <div className="container">
            <div className="row align-items-center justify-content-center row-group">
                <div className="col-sm-6 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="container d-flex justify-content-center cont-img-group">
                        <img className="img-group mt-5"  src={GroupImg} alt="" />
                    </div>
                </div>

                <div className="d-flex col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div className="container d-flex align-items-start cont-text-group ms-lg-3">
                        <h1 className="text-group mt-5" >
                            {t('empresa_analysis_text')}
                        </h1>
                    </div>
                </div>
            </div>
            </div> 
            
        </section>
    )
}


export default Group;

{/* <div className="container">
<div className="row align-items-center justify-content-center row-group">
    <div className="col-sm-6 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
        <div className="container d-flex justify-content-center cont-img-group">
            <img className="img-group mt-5"  src={GroupImg} alt="" />
        </div>
    </div>

    <div className="d-flex col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
        <div className="container d-flex align-items-start cont-text-group ms-lg-3">
            <h1 className="text-group mt-5" >
                {t('empresa_analysis_text')}
            </h1>
        </div>
    </div>
</div>
</div> */}