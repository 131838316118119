import React, { useEffect } from "react";
import HeaderSolucoesCSS from './HeaderSolucoes.css';
import { useTranslate } from "../../helpers/helpers";

function HeaderSolucoes() {
    const t = useTranslate();


    return (
        <section className="header-empresa flex items-center justify-center md:justify-start" style={{height: '80vh'}}>
            <div className="flex flex-col items-center text-center md:items-start text-md-start mx-14">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold my-10 text-black" style={{ fontFamily: 'Anek Latin, sans' }}>
                    {t('solucoes_header_title')}
                </h1>
                <h5 className="text-lg md:text-2xl font-bold text-black" style={{ fontFamily: 'Anek Latin, sans' }}>
                    {t('solucoes_header_subtitle')}
                </h5>
            </div>
        </section>
    );
}

export default HeaderSolucoes;

{/* <div className="container-fluid">
<div className="row d-flex row-header-solucoes">
    <Navbar />

    <div className="col-xxl-12">
        <div className="container-fluid d-flex flex-column container" >
            <h1 className="title-header-solucoes">{t('solucoes_header_title')}</h1>
            <h5 className="subtitle-header-solucoes">{t('solucoes_header_subtitle')}</h5>
        </div>
    </div>
</div>
</div> */}