import React from "react";
import HeaderEmpresaCSS from './HeaderEmpresa.css'
import Navbar from "../Navbar/Navbar";
import { useTranslate } from "../../helpers/helpers";

function HeaderEmpresa() {

    const t = useTranslate();

    return (
        <section className="header-empresa flex items-center justify-center" style={{height: '80vh'}}>
            <div className="flex flex-col items-center text-center md:items-start text-md-start mx-14">
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold my-10 text-black" style={{ fontFamily: 'Anek Latin, sans' }}>
                    {t('empresa_header_title')}
                </h1>
                <h5 className="text-lg md:text-xl md:w-10/12 2xl:w-8/12 font-normal text-black" style={{ fontFamily: 'Anek Latin, sans' }}>
                    {t('empresa_header_subtitle')}
                </h5>
            </div>
        </section>
    )
}

export default HeaderEmpresa;


{/* <div className="flex flex-col items-center justify-center text-center text-md-start md:mx-14">
                
<div className="mt-80">
    <h1 className="text-4xl md:text-6xl my-5 text-black font-bold" style={{fontFamily: 'Anek Latin,sans'}}>{t('empresa_header_title')}</h1>
    <h5 className="text-lg md:text-xl md:w-8/12 font-normal text-black" style={{fontFamily: 'Anek Latin,sans'}}>{t('empresa_header_subtitle')}</h5>
</div>
</div> */}
