import React from "react";
import HistoryCSS from './History.css'
import { useEffect } from 'react'
import { sendWhatsAppMessage, useTranslate } from "../../helpers/helpers";
import InfiniteSlider from './Infinite-Slider/InfiniteSlider'

function History() {
    const t = useTranslate();

    const handleWhatsAppClick = () => {
        const message = "Olá, vim pelo site e quero fazer parte dessa história!";
        sendWhatsAppMessage(message);
    };

  
    return (
        <section>
            <div className="container">
                <div className="row align-items-center row-history">
                    <div className="col-xxl-12 text-center">
                        <div className="container-fluid">
                            <h1 className="title-history" >{t('history_title')}</h1>
                            <h6 className="subtitle-history" >{t('history_subtitle')}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <InfiniteSlider/>
        </section>
    )
}

export default History;