import React, { useEffect } from "react";
import TrustCSS from './Trust.css';
import Polishop from '../../assets/img/Groups/Polishop.png';
import Toyota from "../../assets/img/Groups/Toyota.png";
import Heineken from '../../assets/img/Groups/Heineken.png';
import Tely from '../../assets/img/Groups/Tely.png';
import Caoa from '../../assets/img/Groups/Caoa.png';
import Tenda from '../../assets/img/Groups/Tenda.png';
import OEC from '../../assets/img/Groups/OEC.png';
import Grafisa from '../../assets/img/Groups/Gafisa.png';
import { useTranslate } from "../../helpers/helpers";
import { motion } from "framer-motion";

function Trust() {
    const t  = useTranslate();

    

    return (
        <section>
            <div className="container-fluid">
                <div className="row row-trust justify-content-center align-items-center" data-aos="zoom-in">
                    <div className="col-xxl d-flex flex-column text-center">

                        <div className="container-fluid" >
                            {/* Texto traduzido */}
                            <h6 className="title-trust fw-bold">{t('trust_title')}</h6>
                            <h5 className="fw-normal subtitle-trust">{t('trust_subtitle')}</h5>

                            <div className="d-flex flex-wrap gap-4 justify-content-center align-items-center">
                                <motion.img 
                                whileHover={{ scale: 1.3 }}
                                onHoverStart={e => {}}
                                onHoverEnd={e => {}}
                                className="group1 mx-4" src={Polishop} alt="Polishop" />

                                <motion.img  
                                whileHover={{ scale: 1.3 }}
                                onHoverStart={e => {}}
                                onHoverEnd={e => {}}
                                className="group2 mx-4" src={Toyota} alt="Toyota" />

                                <motion.img 
                                whileHover={{ scale: 1.3 }}
                                onHoverStart={e => {}}
                                onHoverEnd={e => {}}
                                className="group3 mx-4" src={Heineken} alt="Heineken" />

                                <motion.img 
                                whileHover={{ scale: 1.3 }}
                                onHoverStart={e => {}}
                                onHoverEnd={e => {}}
                                className="group4 mx-4" src={Tely} alt="Tely" />

                                <motion.img 
                                 whileHover={{ scale: 1.3 }}
                                 onHoverStart={e => {}}
                                 onHoverEnd={e => {}}
                                className="group5 mx-4" src={Caoa} alt="Caoa" />

                                <motion.img  
                                 whileHover={{ scale: 1.3 }}
                                 onHoverStart={e => {}}
                                 onHoverEnd={e => {}}
                                className="group6 mx-4" src={Tenda} alt="Tenda" />

                                <motion.img 
                                whileHover={{ scale: 1.3 }}
                                onHoverStart={e => {}}
                                onHoverEnd={e => {}}
                                className="group7 mx-4" src={OEC} alt="OEC" />

                                <motion.img 
                                whileHover={{ scale: 1.3 }}
                                onHoverStart={e => {}}
                                onHoverEnd={e => {}}
                                className="group8 mx-4" src={Grafisa} alt="Grafisa" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Trust;