import React, { useEffect } from "react";
import { sendWhatsAppMessage, useTranslate } from "../../helpers/helpers"; // Importa o helper de troca de idioma
import './Header.css';
import Navbar from "../Navbar/Navbar";
import { motion } from "framer-motion";


function Header() {
    const t = useTranslate();

    
    return (
        <section className="header">

            <Navbar/>

            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    zIndex: 1
                }}
            ></div>

            <div className="flex flex-col items-center justify-center text-center text-md-start md:items-start md:justify-end h-75 md:px-14" style={{position: 'relative' ,zIndex: '1'}}>
                <h1 className="text-white px-2 font-bold mt-40 text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl md:w-6/12 lg:w-6/12 xl:w-5/12 2xl:w-5/12 xl:mt-10" style={{fontFamily: 'Anek Latin, sans'}}>{t('titleHeader')}</h1>

                <h5 className="text-white px-2 subtitle font-normal text-sm lg:text-lg my-3 sm:mx-20 md:mx-0 md:w-6/12 lg:w-4/12 xl:w-4/12 2xl:w-4/12" style={{fontFamily: 'Inter, sans'}}>{t('subtitleHeader')}</h5>

                <motion.button 
                whileHover={{ scale: 1.1 }}
                whileTap={{scale: 0.9}}
                onHoverStart={e => {}}
                onHoverEnd={e => {}}
                className="w-10/12 text-xl md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-4/12 mt-20 md:mt-10 text-black font-bold lg:text-xl" 
                style={{fontFamily: 'Anek Latin,sans-serif', borderRadius: '10px', height: '4.4rem', backgroundColor: 'white'}}
                >
                    {t('buttonText')}
                </motion.button> 

            </div>
        </section>
    );
}

export default Header;

{/* <div className="flex flex-col">
    <div className="z-2 my-10">
        <h1 
        className="text-white font-bold text-2xl my-3" 
        style={{fontFamily: 'Anek Latin,sans'}}>{t('titleHeader')}
        </h1>

        <p 
        style={{fontFamily: 'Anek Latin,sans'}}
        className="text-white font-normal text-lg px-10">{t('subtitleHeader')}
        </p>
    </div>

    <div className="z-2 w-screen">
        <motion.button 
        whileHover={{ scale: 1.1 }}
        whileTap={{scale: 0.9}}
        onHoverStart={e => {}}
        onHoverEnd={e => {}}
        className="bg-white font-bold text-black w-10/12" style={{height: '3.5rem', borderRadius: '12px'}}>{t('buttonText')}</motion.button>
    </div>
</div> */}
